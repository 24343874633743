import React from 'react';
import { frontAppUrl } from 'utils/siteConfig';

export const OnboardingLink = (props) => {
  const spe = props.spe;
  const url = `${frontAppUrl}/onboarding.html?spe=${spe}&refresh=true`

  return (
    <a href={url}>
      {props.children}
    </a>
  );
};
