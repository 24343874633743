import React from 'react';
import CharlesMeta from 'components/common/meta/CharlesMeta';
import Helmet from 'react-helmet';
import Image from 'components/common/utils/Image';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Layout } from 'components/common';

import Logo from 'images/mia-logo-blue.svg';
import PhoneRinging from 'images/icons/start/Phone.svg';
import Support from 'images/icons/start/support.svg';
import France from 'images/icons/start/France.svg';
import HADS from 'images/icons/start/HADS.svg';
import Mastercard from 'images/icons/start/Mastercard.svg';
import Visa from 'images/icons/start/Visa.svg';
import SSL from 'images/icons/start/SSL.svg';
import Mangopay from 'images/icons/start/Mangopay.svg';

import { OnboardingLink } from '../OnboardingLink';
import StaticStepper from '../StaticStepper';
import StartFooterRow from '../StartFooterRow';

import bullets from '../data';
import Bullet from '../Bullet';

import '../styles.sass';

const IndexPage = ({ pageContext: pathology }) => (
  <>
    <CharlesMeta
      data={{
        title: pathology.meta.title,
        description: pathology.meta.description,
      }}
    />
    <Helmet>
      <html className="html-class-small-font-page" />
    </Helmet>
    <Layout
      showBars={false}
      showNewsletterBeforeFooter={false}
    >
      <div className="text-center text-md-left start-page lc-light-grey vh-100 w-100">
        <Container className="top-container d-flex justify-content-between">
          <Logo className="start-logo" />
          <a className="phone-charles-block d-flex" href="tel:0186651733">
            <div className="phone-div d-flex align-items-center">
              <Support height={30} />
              <span className="hide-number ml-2">01 86 65 18 91</span>
              <France height={30} className="logo-france" />
            </div>
          </a>
        </Container>
        <Container>
          <StaticStepper className="" />
        </Container>
        <Container className="pb-3 pb-sm-5 main-container">
          <Row className="main-start-panel">
            <Col xs={12} xl={{ span: 7 }} className="lc-white left-col">
              <div className="d-flex flex-column left-div justify-content-between">
                <div>
                  <h1 className="start-title">
                    {pathology.h1}
                  </h1>
                  <div className="d-flex align-items-center main-start-doctor justify-content-center">
                    <div className="main-start-doctor-image">
                      <Image
                        className="rounded-circle"
                        style={{ backgroundColor: 'white' }}
                        filename="doctors/camille-face.png"
                        alt="Camille Bataillon, directeur scientifique de Charles.co"
                      />
                    </div>
                    <div className="main-start-doctor-text d-flex flex-column justify-content-center text-center text-xl-left">
                      Je suis Camille Bataillon, la sexologue qui a créé ce protocole de
                      téléconsultation.
                    </div>
                  </div>
                  <div className="text-center cta-consulter d-block d-sm-none">
                    <OnboardingLink spe={pathology.specialtySlug}>
                      <button className="btn btn-orange style-most-cta mobile-start-cta">
                        CONSULTER
                      </button>
                    </OnboardingLink>
                  </div>
                  <div>
                    <p className="start-text">
                      La consultation en ligne commence par un questionnaire qui permet d’informer
                      la sexologue de votre état de santé sexuelle et de vos antécédents médicaux. Ces
                      informations sont sécurisées et confidentielles, seule la sexologue
                      les analysera pour évaluer vos symptômes et vous recommander un protocole
                      d’accompagnement personnalisé.
                    </p>
                    <div className="text-center d-none d-sm-block cta-div">
                      <OnboardingLink spe={pathology.specialtySlug}>
                        <button className="btn btn-orange style-most-cta m-0">
                          CONSULTER
                        </button>
                      </OnboardingLink>
                    </div>
                  </div>
                </div>
                <div className="secure-logo-container text-center secure-logo-container-absolute">
                  <span className="secure-by">Sécurisé par</span>
                  <div className="d-flex justify-content-between align-items-center secure-logos">
                    <Mastercard height={30} className="mr-1 mr-sm-0" />
                    <SSL height={30} />
                    <Visa height={30} />
                    <HADS height={80} />
                    <Mangopay height={30} />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} xl={{ span: 5 }} className="right-col background-color-pale-yellow">
              <div className="text-left right-div">
                <div className="d-flex flex-row justify-content-between flex-wrap bullet-wrap">
                  {bullets.map((bullet, index) => (
                    <Bullet data={bullet} key={`bullet_${index}`} />
                  ))}
                </div>
              </div>
            </Col>
          </Row>
          <StartFooterRow className="footer-radius" />
        </Container>
      </div>
    </Layout>
  </>
);

export default IndexPage;
